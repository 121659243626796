import { Box, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useState } from 'react';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	selectTransactionOverview,
	selectTransactionTitle,
} from 'src/redux/slices/transaction';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { PropertyTitle } from 'src/types';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { parseStringToJson } from 'src/utils/strings';
import { RESPONSE_CODES } from 'src/constants';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(selectModal<PropertyTitle>);
	const [isLoading, setIsLoading] = useState(false);

	const handleConfirm = async () => {
		try {
			setIsLoading(true);

			await dispatch(selectTransactionTitle({ title: payload })).unwrap();

			enqueueSnackbar('Transaction title successfully selected!');
			dispatch(getTransactionSummaryThunk({ id: transaction.id, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transaction.id }));
			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) {
				const response = parseStringToJson(e.message);

				if (typeof response === 'string') {
					enqueueSnackbar(response, { variant: 'error' });
				} else if (response?.code) {
					switch (response?.code) {
						case RESPONSE_CODES.HMLR.TITLE_PENDING_APPLICATION:
							dispatch(openModal({ name: modals.forceTitleAssignment, payload }));
							break;
						default:
							break;
					}
				}
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle} pt={0}>
				CONFIRM TITLE
			</Typography>
			<Typography>
				You have selected{' '}
				<b>
					{payload.number} ({labelByPropertyTitleTenure[payload.tenure]})
				</b>
				.
				<br />
				<br />
				By clicking 'Confirm', the <b>Register</b> and <b>Plan</b> for this title will be downloaded, incurring an{' '}
				<b>£8</b> charge. You may change your title selection later; however, an additional <b>£8</b> charge will apply.
			</Typography>
			<ActionButtons mt='20px' display='flex' justifyContent='flex-end' gap='20px !important'>
				<LoadingButton variant='outlined' size='large' onClick={handleCloseModal} loading={isLoading}>
					Back
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleConfirm}>
					Confirm
				</LoadingButton>
			</ActionButtons>
		</Box>
	);
};

const TitleAssignmentConfirmationModal = () => (
	<Modal name={modals.titleAssignmentConfirmation} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default TitleAssignmentConfirmationModal;
