import { Box, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useState } from 'react';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	selectTransactionOverview,
	selectTransactionTitle,
} from 'src/redux/slices/transaction';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import { PropertyTitle } from 'src/types';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(selectModal<PropertyTitle>);
	const [isLoading, setIsLoading] = useState(false);

	const handleContinue = async () => {
		try {
			setIsLoading(true);

			await dispatch(selectTransactionTitle({ title: payload, force: true })).unwrap();

			enqueueSnackbar('Transaction title successfully selected!');
			dispatch(getTransactionSummaryThunk({ id: transaction.id, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transaction.id }));
			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle} pt={0}>
				PENDING APPLICATION(S) ON TITLE
			</Typography>
			<Typography>
				<Box color='red' component='b'>
					The title number provided has application(s) pending against it (e.g. lease extension, etc).
				</Box>{' '}
				<br />
				<br />
				<b>[Continue]</b> to make request for backdated office copies, or <b>[Cancel]</b> to exit.
			</Typography>
			<ActionButtons mt='20px' display='flex' justifyContent='flex-end' gap='20px !important'>
				<LoadingButton variant='outlined' size='large' onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleContinue}>
					Continue
				</LoadingButton>
			</ActionButtons>
		</Box>
	);
};

const ForceTitleAssignmentModal = () => (
	<Modal name={modals.forceTitleAssignment} cardSx={{ overflow: 'visible', maxWidth: '625px' }}>
		<Content />
	</Modal>
);

export default ForceTitleAssignmentModal;
